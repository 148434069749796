import { Typography } from '@material-ui/core';
import React, {useEffect} from 'react';
import { BizMoney } from "./BizMoney";

export const Dashboard = () => {
  return (
    <div>
      <h1>Admin Page</h1>
      <BizMoney />
      <Typography paragraph>Release Version: {process.env.REACT_APP_RELEASE_TIME || "DEV"}</Typography>
      <Typography paragraph>Newsletter의 metadata를 관리하는 기능</Typography>
      <Typography paragraph>Pagination 이 적용되지 않아 속도가 느리고 버벅임이 있습니다..</Typography>

      <Typography paragraph>[2021-05-14] 회원가입 현황페이지에서 twigfarm.net 계정으로 가입된 사용자는 통계에서 제외되도록 처리하였습니다.</Typography>
      <Typography paragraph>[2021-05-21] 회원리스트 페이지에서 가입일자 내림차순 정렬로 변경되었습니다.</Typography>
      <Typography paragraph>[2021-06-02] 뉴스레터 페이지에서 등록일자 내림차순 정렬로 변경되었습니다.</Typography>
      <Typography paragraph>[2021-07-28] 뉴스레터 페이지에서 태그 컬럼이 추가되었습니다.</Typography>
      <Typography paragraph>[2021-08-02] 통계를 위한 회원필터가 적용되었습니다.</Typography>
      <Typography paragraph>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;@twigfarm.net:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;seoksik.kim, jhlee, dylee, sanghyun.lee, sophie.ahn, hyuntaek.park, jihye.suh+1, jhlee1
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--------------------------
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;@gmail.com (해당 아이디의 ‘+’계정도 포함) :<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;nocliche, 1991may30, shlee2112, leezyizzy, haneldream, sskim, seoksik, beaman76, orgpark, sophiajhahn,
        sophie.hyun.ahn, s.ahndaeun, jjc1139, tnsrb, seoksik.dev, sskim.twigfarm
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--------------------------
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;기타 제외 계정:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;@devugfactory.com, abc1@aaa.com, aaaa@aaaaa.com, abc@abc.com, akakalal@akakaka.com, ababaab@ananab.com,
        dd@dd.com, anc@aa.com
      </Typography>
      <Typography paragraph>[2021-08-19] 뉴스레터 썸네일 업로드 기능이 추가되었습니다.</Typography>
      <Typography paragraph>[2021-08-19] 뉴스레터 pagination 기능 적용되었습니다.</Typography>
      <Typography paragraph>
        [2021-08-19] 회원필터에 아래 구독이메일이 추가로 제외 되었습니다.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'news.yangprod444@heybunny.io', 'news.yangprod04@heybunny.io', 'news.joybang@heybunny.io',
        'news.celine@heybunny.io'
      </Typography>
      <Typography paragraph>[2021-11-16] 페이지뷰 현황 페이지가 추가되었습니다.</Typography>
      <Typography paragraph>[2022-03-29] 배너관리 페이지가 추가되었습니다.</Typography>
      <Typography paragraph>
        [2022-03-29] 통계를 위한 회원필터가 적용되었습니다.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;@gmail.com (해당 아이디의 ‘+’계정도 포함) :<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;forever8528+????@gmail.com, youngwon.kim@twigfarm.net (김영원매니저)
      </Typography>
      <Typography paragraph>[2022-04-11] 회원리스트 pagination 기능 적용되었습니다.</Typography>
      <Typography paragraph>
        [2022-07-4] 회원필터에 아래 SNS이메일이 추가로 제외 되었습니다.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'add_paper@naver.com', 'beaman76@gmail.com', 'dlwlgp13@naver.com', 'forever8528@gmail.com',
        'forever8528@naver.com', 'jcjung90@kakao.com', 'jjc1139@naver.com', 'looneman@hanmail.net', 'n65ccpdykj@privaterelay.appleid.com',
        'sskim-twigfarm-test@naver.com', 'sskim.india@gmail.com', 'sskim.twigfarm.test1@gmail.com', 'sskim.twigfarm@gmail.com', 'sunkyu.dfactory@gmail.com',
        'sunkyu.test@gmail.com', 'vhfpqj28510@naver.com', 'vwtdnftk8q@privaterelay.appleid.com', 'xqp4hvjwk4@privaterelay.appleid.com'
      </Typography>
    </div>
  );
};
