import axios from "axios";
import { useEffect, useState } from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ExtendedTheme } from "../theme";
import { getBizMoney } from "../utils/api";
import { useAuth0 } from "@auth0/auth0-react";
import { setAccessToken } from "../utils/auth";

const useStyles = makeStyles((theme: ExtendedTheme) =>
  createStyles({
    main: {
      display: "flex",
      fontWeight: "bold",
      height: "50px",
      fontSize: "18px",
      marginBottom: "25px",
      alignItems: "center",
      gap: "10px",
      justifyContent: "flex-start",
    },
  })
);

export const BizMoney = () => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();

  const [money, setMoney] = useState<number>(-1);

  useEffect(() => {
    constructUserStat();
    // getLocalBizMoney();
  }, []);

  const constructUserStat = async () => {
    const token = await getAccessTokenSilently();
    setAccessToken(token);

    const bizmoney = await getBizMoney();
    setMoney(Number(bizmoney.result.balance) || -1);
  };

  return (
    <div className={classes.main}>
      <span>{`<< 비즈머니 잔액 : ${new Intl.NumberFormat("ko-KR").format(money)}원 >>`}</span>
      {money < 100000 && <span style={{ color: "red", fontSize: "14px" }}>비즈머니를 충전해주세요!!!</span>}
    </div>
  );
};
